export const profileMockData: any = {
  _id: "65fc5712ed5f13b56354a7a2",
  firstname: "Apurba",
  lastname: "Panja",
  intro:
    "I'm a software engineer based in Kolkata. I'm committed to continually growing and innovating within the ever-evolving field of software development.",
  jobs: ["Software Engineer", "React Developer", "Java Full Stack Developer"],
  bio: '<p>Hey there!👋 I\'m a <b>Full Stack Developer</b>🧑🏻‍💻 at <b><i>Tata Consultancy Services</i></b>, where I proficiently leverage a diverse set of tools in the <b style="color: #5f6368;">React</b> ecosystem, including <b style="color: #5f6368;">HTML, CSS, JavaScript (ES6+), TypeScript, React-router, Redux, Storybook, and testing libraries</b>. My primary focus is on crafting intuitive user interfaces and enhancing user experiences through seamless design implementation.<br><br>Beyond front-end development, I adeptly employ <b style="color: #5f6368;">Node.js</b>, utilizing frameworks like <b style="color: #5f6368;">Express.js and Nest.js, alongside MongoDB</b>, to create RESTful APIs. My expertise in these technologies, honed through personal projects, enables me to build complex and scalable solutions efficiently.<br><br>My proficiency extends beyond JavaScript to encompass <b style="color: #5f6368;">Java and Python</b>, enhancing my versatility in software development. Driven by curiosity and a commitment to innovation, I\'m dedicated to learning and staying updated with the latest technologies. I eagerly seek opportunities to contribute to impactful projects and collaborate with like-minded professionals pushing the boundaries of innovation.<br><br>Outside of work, I enjoy reading books, and watching cricket & football.</p>',
  contactDetails: {
    address: "Kolkata, West Bengal, India",
    email: "apurbapanja1@gmail.com",
  },
  socialAccounts: {
    linkedIn: "https://www.linkedin.com/in/iapurba/",
    github: "https://github.com/iapurba",
    facebook: "https://www.facebook.com/apurbapanja30",
    instagram: "https://www.instagram.com/iapurba.insta/",
    x: "https://twitter.com/iapurba_se",
  },
  profileImageUrl: `${process.env.PUBLIC_URL}/images/profile.gif`,
  bioImageUrl: `${process.env.PUBLIC_URL}/images/bio-image.png`,
  downloadCvUrl:
    "https://drive.google.com/file/d/1THwN8wpaJtIZGqsqmVjiboKg42UpvENP/view",
  __v: 0,
  updatedAt: "2024-04-04T17:04:11.106Z",
  email: "apurbapanja1@gmail.com",
};
